const authProvider = {
  register: ({ first_name, email, password, phone, business_name }) => {
    // accept all username/password combinations
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password,
        first_name,
        phone,
        business_name,
      }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/register`, requestOptions) //teayudo.com.bo/api/register
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("token", user.data.token);
        localStorage.setItem("role", user.user.role);
        localStorage.setItem("profile", JSON.stringify(user.user));
        return user;
      });
  },
  // called when the user attempts to log in
  login: ({ email, password }) => {
    // accept all username/password combinations
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email: email,
        password,
        role: "proveedor-empresa",
      }),
    };

    return fetch(`${process.env.REACT_APP_API_URL}/login`, requestOptions) //http://teayudo.com.bo/api/login
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem("token", user.data.token);
        localStorage.setItem("role", user.user.role);
        localStorage.setItem("profile", JSON.stringify(user.user));        
        //  return user;
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("profile");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status, error }) => {
    if (status === 401 || status === 403) {
      console.log(error);
      localStorage.removeItem("token");
      localStorage.removeItem("role");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.resolve("guest");
  },
};

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem("token");
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    if (data.error) {
      return Promise.reject(data.error);
    }

    if (
      data.user &&
      data.user.role !== "admin" &&
      data.user.role !== "proveedor-empresa" &&
      data.user.role !== "proveedor-producto" &&
      data.user.role !== "marketing" &&
      data.user.role !== "admin-sucursal"
    ) {
      return Promise.reject("Servicio solo para empresas registradas");
    }
    if (
      data.user &&
      data.user.role !== "admin" &&
      !data.user.provider_verified
    ) {
      return Promise.reject(
        "Debe esperar a que el administrador apruebe su cuenta."
      );
    }
    return data;
  });
}

export default authProvider;
