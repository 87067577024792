import React, { useState } from "react";
import {
  SelectInput,
  Filter,
  List as BaseList,
  Datagrid,
  TextField,
  DateInput,
  DateField,
  ArrayField,
  SingleFieldList,
  ChipField,
  BooleanField,
  TopToolbar,
  FilterButton,
  CreateButton,
  usePermissions,
} from "react-admin";
import Firebase from "../Firebase/Firebase";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import ExportButton from "../components/ExcelExportButton";
import useExport from "../components/UseExcelExport";
import moment from "moment/moment";

const BookingFilter = [
  <SelectInput
    label="Estado"
    source="status"
    choices={[
      { id: "Pendiente de cotizacion", name: "Pendiente de cotizacion" },
      { id: "Cotizacion enviada", name: "Cotizacion enviada" },
      { id: "Pendiente", name: "Pendiente" },
      { id: "Confirmado", name: "Confirmado" },
      { id: "En proceso", name: "En proceso" },
      { id: "Finalizado", name: "Finalizado" },
      { id: "Cancelada por el proveedor", name: "Cancelada por el proveedor" },
      { id: "Cancelada por el cliente", name: "Cancelada por el cliente" },
    ]}
  />,
  <DateInput label="Desde" source="bookingDate>" />,
  <DateInput label="Hasta" source="bookingDate<" />
];

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("booking");
  const [openModal, setOpenModal] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {
      setOpenModal(true);
      await getExportData();
      setOpenModal(false);
    }
  };

  const columns = [
    { label: "ID", source: "_id" },
    { label: "Fecha de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('YYYY-MM-DD') }, 
    { label: "Hora de la creación de la reserva", source: "createdAt", transform: (val) => moment(val).format('HH:mm') },
    { label: "Fecha de la reserva", source: "bookingDate", transform: (val) => moment(val).format('YYYY-MM-DD') },
    { label: "Hora de la reserva", source: "bookingDate", transform: (val) => moment(val).format('HH:mm') }, 
    { label: "Precio Total", source: "paymentInfo.totalPrice" },
    { label: "Método de Pago", source: "paymentInfo.paymentMethod" },
    { label: "Estado de Reserva", source: "status" },
    { label: "Estado de Pago", source: "paymentInfo.status" }
  ];

  return (
    <TopToolbar
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={props.filters} /> {/* Se pasa correctamente el array de filtros */}
        <CreateButton basePath={props.basePath} />
      </div>
      <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="bookings.xlsx" />
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => {
  
  const { permissions } = usePermissions();
  const profile = JSON.parse(localStorage.getItem("profile"));
  console.log(profile);

  return (
  <Firebase>
    <BaseList
      sort={{ field: "bookingDate", order: "DESC" }}
      filters={BookingFilter}        
      {...props}
      actions={<ListActions filters={BookingFilter} />}  
    >
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <TextField source="_id" label="ID" />
        <DateField
          source="bookingDate"
          label="Fecha de Inicio"
          showTime
          locales="es-ES"
        />
        <ArrayField source="serviceCart" label="Servicios Solicitados">
          <SingleFieldList>
            <ChipField source="service.name" />
          </SingleFieldList>
        </ArrayField>
        <TextField source="paymentInfo.totalPrice" label="Precio Total (Bs.)" />
        <TextField source="paymentInfo.paymentMethod" label="Método de Pago" />
        <TextField source="status" label="Estado de Reserva" />
        <TextField source="paymentInfo.status" label="Estado de Pago" />
        <TextField source="feedbackInfo.rating" label="Rating" />
        <BooleanField source="isInBranch" label="¿Servicio en Sucursal?" />
        {permissions !== "admin-sucursal" && <TextField source="branch.name" label="Sucursal" />}
      </Datagrid>
    </BaseList>
  </Firebase>
)};
