import React, { useState } from "react";
import {
  List as BaseList,
  Datagrid,
  TextField,
  DateField,
  FunctionField,  
  CreateButton,
  TopToolbar,
  useNotify,
  FilterButton,
  usePermissions,

} from "react-admin";
import RegexSearchInput from "../../components/RegexSearchInput";
import moment from "moment";
import DownloadCsvButton from "../../components/downloadCsv";
import { ImportButton } from "react-admin-import-csv";
import { Box, CircularProgress, Dialog, DialogContent, Link, Typography } from "@mui/material";
import ExportButton from "../../components/ExcelExportButton";
import useExport from "../../components/UseExcelExport";


const NameFilter = [
  <RegexSearchInput source="fullName" label="Nombre" />,
  <RegexSearchInput source="phone" label="Teléfono" />,
];

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("enterprise/customers");
  const [openModal, setOpenModal ] = useState(false);
  const permissions = usePermissions();

  const handleExportClick = async () => {
    if (!exportData.length) {      
      setOpenModal(true);
      await getExportData(); 
      setOpenModal(false)
    }
  };

  const {
    className,
    basePath,
  } = props;
  const notify = useNotify();
  const [errors, setErrors] = useState([]);
  const errorsToString = (errors) => {
    return errors.reduce((acc, error) => {
      return `${acc ? acc + "\n" : ""}${error}`;
    }, "");
  };

  const columns = [
    { label: "Nombre Completo", source: "fullName" },
    { label: "Teléfono", source: "phone" },
    { label: "Email", source: "email" },    
    { label: "Fecha de Nacimiento", source: "birthdate", transform: (val) => moment(val).format("DD/MM/YYYY") },
    
  ];

  return (
    <TopToolbar
      className={className}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 16px",
        marginBottom: "0",
      }}
    >
      <div style={{ display: "flex", gap: "16px" }}>
        <FilterButton filters={NameFilter} />
        <CreateButton basePath={basePath} />
      </div>
      {permissions === "admin-sucursal" && <ExportButton onClick={handleExportClick} data={exportData} columns={columns} filename="customers.xlsx" />}
      <div style={{ display: "flex", gap: "16px" }}>
        { permissions === "admin-sucursal" &&
          <ImportButton
          {...props}
          disableImportOverwrite={true}
          transformRows={(csvRows) => {
            return csvRows.map((row) => {
              const transformedRow = {};
              transformedRow.fullName = row.Nombre;
              if (row.Telefono) transformedRow.phone = row.Telefono;
              if (row.Email) transformedRow.email = row.Email;
              transformedRow.addresses = [];
              return transformedRow;
            });
          }}
          validateRow={async (csvRowItem, row) => {
            if (row === 0) {
              setErrors([]);
            }
            if (!csvRowItem.fullName) {
              errors.push(`Fila ${row + 1}: Nombre es requerido`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Nombre es requerido");
            }
            if (csvRowItem.phone && isNaN(csvRowItem.phone)) {
              errors.push(`Fila ${row + 1}: Telefono debe ser un numero`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Telefono debe ser un numero");
            }
            if (csvRowItem.email && !csvRowItem.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
              errors.push(`Fila ${row + 1}: Email invalido`);
              notify(errorsToString(errors), { type: "error", multiLine: true });
              throw new Error("Email invalido");
            }
          }}
        />
        }
        {permissions === "admin-sucursal" && <DownloadCsvButton />}
      </div>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
};

export const List = (props) => {
  const { permissions } = usePermissions();

  const profile = JSON.parse(localStorage.getItem("profile"));

  return (
  <BaseList
    {...props}
    filters={NameFilter}
    actions={<ListActions />}
    filter={permissions !== "admin-sucursal" ? null : { providerId: profile.employer._id }}
  >
    <Datagrid rowClick="edit" bulkActionButtons={false}>

      <TextField source="fullName" label="Nombre Completo" />
      <TextField source="phone" label="Teléfono" />
      <TextField source="email" label="Email" />
      <TextField source="gender" label="Género" />
      <DateField source="birthdate" label="Fecha de Nacimiento" />
      <FunctionField
        label="Fecha de Ultimo servicio"
        sortBy="lastBooking.bookingDate"
        render={(record) => {
          const date = record.lastBooking
            ? moment(record.lastBooking.bookingDate)
            : null;
          return record.lastBooking?.bookingDate ? (
            <Link href={"#/booking/" + record.lastBooking._id + "/show"}>{date.format("DD/MM/YYYY")}</Link>
          ) : (
            ""
          );
        }}
      />
      <FunctionField
        label="Dias desde el ultimo servicio"
        sortBy="lastBooking.bookingDate"
        render={(record) => {
          const diff = record.lastBooking
            ? moment().diff(moment(record.lastBooking.bookingDate), "days")
            : null;
          return record.lastBooking?.bookingDate ? diff : "";
        }}
      />
    </Datagrid>
  </BaseList>
  )
};
