import React, { useState, useEffect, useRef } from "react";
import {
  useListContext,
  ListBase,
  Link,
  Filter,
  BooleanInput,
  ReferenceInput,
  useChoicesContext,
  useGetList,
  usePermissions,
} from "react-admin";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  Button,
  ButtonBase,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { es } from "date-fns/esm/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
import { format } from "date-fns";
import Firebase from "../Firebase/Firebase";

export const ScheduleList = (props) => {
  const [date, setDate] = useState(new Date().toISOString());
  const [timeUnit, setTimeUnit] = useState("day");
  const [serviceMethod, setServiceMethod] = useState("Ambos");
  const [branch, setBranch] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { permissions } = usePermissions();

  useEffect(() => {
    if (searchParams.get("date")) {
      setDate(new Date(searchParams.get("date")));
    }
  }, [searchParams.get("date")]);

  useEffect(() => {
    if (searchParams.get("branch")) {
      setBranch(searchParams.get("branch"));
    }
  }, [searchParams.get("branch")]);

  useEffect(() => {
    if (searchParams.get("serviceMethod")) {
      setServiceMethod(searchParams.get("serviceMethod"));
    }
  }, [searchParams.get("serviceMethod")]);

  return (
    <Firebase>
      <ListBase
        {...props}
        filter={{
          date: date,
          timeUnit: timeUnit,
          serviceMethod: serviceMethod,
          branch: branch,
        }}
      >
        <br />
        <FormControl>
          <InputLabel id="demo-simple-select-label">Método</InputLabel>
          <Select
            style={{ width: 200 }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={serviceMethod}
            label="Método"
            onChange={(e) => {
              setServiceMethod(e.target.value);
              if (e.target.value === "A domicilio") {
                setBranch(null);
              }
            }}
          >
            <MenuItem value="Ambos">Ambos</MenuItem>
            <MenuItem value="En sucursal">En sucursal</MenuItem>
            <MenuItem value="A domicilio">A domicilio</MenuItem>
          </Select>
        </FormControl>
        <br />
        { permissions !== "admin-sucursal" && <SelectBranchInput
          serviceMethod={serviceMethod}
          branch={branch}
          setBranch={setBranch}
        /> 
        }
        <Schedule
          serviceMethod={serviceMethod}
          branch={branch}
          setDate={setDate}
          setTimeUnit={setTimeUnit}
        />
      </ListBase>
    </Firebase>
  );
};

const CustomEditor = ({ scheduler, serviceMethod, branch }) => {
  const navigate = useNavigate();
  return (
    <DialogContent>
      <Typography>
        Hora: {scheduler?.state?.start?.value.toLocaleTimeString("es-ES")}
      </Typography>
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/booking/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&serviceMethod=${serviceMethod}&branch=${branch}&redirect=schedule`
          )
        }
        fullWidth
      >
        Crear Reserva
      </Button>
      <br />
      <br />
      <Button
        variant="outlined"
        onClick={() =>
          navigate(
            `/unavailability/create?date=${scheduler?.state?.start?.value.toISOString()}&employee=${
              scheduler.admin_id
            }&redirect=schedule`
          )
        }
        fullWidth
      >
        Horario No Disponible
      </Button>
      <br />
      <br />
      <Button variant="contained" onClick={scheduler.close} fullWidth>
        Cancelar
      </Button>
    </DialogContent>
  );
};

const Schedule = ({ date, setDate, setTimeUnit, serviceMethod, branch }) => {
  const { data, isLoading } = useListContext();
  const calendarRef = useRef(null);
  useEffect(() => {
    if (Array.isArray(data)) {
      calendarRef.current.scheduler.handleState(data[0].resources, "resources");
      calendarRef.current.scheduler.handleState(
        data[0].events.map((e) => {
          return {
            ...e,
            start: new Date(e.start),
            end: new Date(e.end),
          };
        }),
        "events"
      );
    }
  }, [data, date]);

  const [searchParams, setSearchParams] = useSearchParams();
  let initialDate = new Date();
  if (searchParams.get("date")) {
    initialDate = new Date(searchParams.get("date"));
  }
  return (
    <Scheduler
      ref={calendarRef}
      view="day"
      events={[]}
      resources={[]}
      resourceFields={{
        idField: "admin_id",
        textField: "fullName",
        subTextField: "phone",
        avatarField: "fullName",
        colorField: "color",
      }}
      day={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
      }}
      week={{
        startHour: 8,
        endHour: 22,
        step: 30,
        navigation: true,
      }}
      translations={{
        navigation: {
          month: "Mes",
          week: "Semana",
          day: "Día",
          today: "Hoy",
        },
        form: {
          addTitle: "Crear Reserva",
          editTitle: "Ver Reserva",
          confirm: "Confirmar",
          delete: "Borrar",
          cancel: "Cancelar",
        },
        event: {
          title: "Título",
          start: "Inicio",
          end: "Final",
          allDay: "Todo el día",
        },
        moreEvents: "Más...",
        loading: "Cargando...",
      }}
      locale={es}
      selectedDate={initialDate}
      onSelectedDateChange={(d) => setDate(d.toISOString())}
      onViewChange={(v) => setTimeUnit(v)}
      deletable={false}
      draggable={false}
      editable={false}
      customEditor={(scheduler) => (
        <CustomEditor
          scheduler={scheduler}
          branch={branch}
          serviceMethod={serviceMethod}
        />
      )}
      eventRenderer={({ event, onClick }) => (
        <ButtonBase onClick={onClick}>
          <div
            style={{
              padding: "2px 6px",
              backgroundColor: event.color,
              height: "100%",
            }}
          >
            <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
              {event.title}
            </Typography>
            <Typography style={{ fontSize: 11 }} noWrap>
              {format(event.start, `HH:mm`, {
                locale: es,
              })}{" "}
              -
              {format(event.end, `HH:mm`, {
                locale: es,
              })}
            </Typography>
            {event.type === "booking" && (
              <Typography variant="subtitle2" style={{ fontSize: 12 }} noWrap>
                Estado: {event.status}
              </Typography>
            )}
          </div>
        </ButtonBase>
      )}
      viewerExtraComponent={(fields, event) => {
        console.log("event",event);
        return (
          <div>
            <Typography>ID: {event.event_id}</Typography>
            <Typography>Estado: {event.status}</Typography>
            <Link
              to={`/${event.type}/${event.event_id}/show?redirect=schedule`}
            >
              Más información
            </Link>
          </div>
        );
      }}
    />
  );
};

const SelectBranchInput = ({ serviceMethod, branch, setBranch }) => {
  const { data, loading, error } = useGetList("enterprise/branches", {
    filter: {
      status: true,
    },
  });

  return serviceMethod !== "A domicilio" ? (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Sucursal</InputLabel>
      <Select
        style={{ width: 200 }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={branch}
        label="Método"
        onChange={(e) => setBranch(e.target.value)}
      >
        {data?.map((e) => {
          return (
            <MenuItem key={e._id} value={e._id}>
              {e.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  ) : (
    <React.Fragment />
  );
};
