import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  List as BaseList,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  SingleFieldList,
  ChipField,
  ReferenceArrayField,
  TopToolbar,
  CreateButton,
  usePermissions,
} from "react-admin";
import useExport from "../components/UseExcelExport";
import ExportButton from "../components/ExcelExportButton";
import { transform } from "typescript";
import moment from "moment";

const ListActions = (props) => {
  const { getExportData, exportData, loading } = useExport("coupon");
  const [ openModal, setOpenModal ] = useState(false);

  const handleExportClick = async () => {
    if (!exportData.length) {      
      setOpenModal(true);
      await getExportData(); 
      setOpenModal(false);
    }
  };

  const { permissions } = usePermissions();

  const columns = [
    { label: "Nombre", source: "name", width: 30 },
    { label: "Código", source: "code", width: 15 },
    { label: "Fecha de expiración", source: "expirationDate", width: 15, transform: (val) => moment(val).format("YYYY-MM-DD") },
    { label: "Usos restantes", source: "usesLeft", width: 15 },
    { label: "Usos máximos", source: "maxUses", width: 30 },
    { label: "Descripción", source: "description", width: 75 },
    { label: "Tipo de descuento", source: "discountType", width: 15 },
    { label: "Descuento", source: "discount", width: 10 },
    { label: "Servicios Válidos", source: "validServices", width: 150 },
  ];
  
  return (
    <TopToolbar>
      <Box display="flex" justifyContent="center" width="100%">
        {permissions !== "admin-sucursal" && <CreateButton />}
        <ExportButton
        onClick={handleExportClick}
        data={exportData}
        columns={columns}
        filename="cupones.xlsx"
        loading={loading}
      />
      </Box>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CircularProgress />
            <Typography variant="body1" style={{ marginTop: 16 }}>
              Cargando datos para exportación...
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </TopToolbar>
  );
}

export const List = (props) => (
  <BaseList {...props} actions={<ListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="name" label="Nombre" />
      <TextField source="code" label="Código" />
      <DateField source="expirationDate" label="Fecha de expiración" />
      <NumberField source="usesLeft" label="Usos restantes" />
      <NumberField source="maxUses" label="Usos máximos" />
      <TextField source="description" label="Descripción" />
      <TextField source="discountType" label="Tipo de descuento" />
      <NumberField source="discount" label="Descuento" />
      <ReferenceArrayField
        source="validServices"
        label="Servicios Válidos"
        reference="service"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
    </Datagrid>
  </BaseList>
);
