import { DashboardMenuItem, Menu, ResourceMenuItem, usePermissions, useResourceDefinitions, useSidebarState } from "react-admin";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RedeemIcon from '@mui/icons-material/Redeem';
import SettingsIcon from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import BuildIcon from "@mui/icons-material/Build";
import ShieldIcon from "@mui/icons-material/Shield";
import { useEffect, useState } from "react";

const MENU_STATE_DEFAULT = { settings: false, tools: false, other: false };

export const CustomMenu = () => {
  const resources = useResourceDefinitions();
  const [open, setOpen] = useSidebarState();
  const [menuState, setMenuState] = useState(MENU_STATE_DEFAULT);
  const { permissions } = usePermissions();

  const settingsResources = Object.keys(resources)
    .filter((name) => resources[name].hasList && resources[name].options?.parent === "settings")
    .map((name) => ({
      name,
      label: resources[name].options?.label || name,
    }));

  const toolsResources = Object.keys(resources)
    .filter((name) => resources[name].hasList && resources[name].options?.parent === "tools")
    .map((name) => ({
      name,
      label: resources[name].options?.label || name,
    }));

  const otherResources = Object.keys(resources)
    .filter((name) => resources[name].hasList && resources[name].options?.parent !== "settings" && resources[name].options?.parent !== "tools")
    .map((name) => ({
      name,
      label: resources[name].options?.label || name,
    }));
  useEffect(() => {
    if (open) {
      
    } else {
      setMenuState(MENU_STATE_DEFAULT);
    }
  }, [open]);

  const handlerChange = (expanded, accordion) => {
    setMenuState({ ...menuState, [accordion]: expanded });
    if (expanded) {
      setOpen(true);
    }
  };
    
  const profile = JSON.parse(localStorage.getItem("profile"));
  const branchId = permissions !== "admin-sucursal" ? null: profile.branch._id;

  return (
    <Menu>
      <DashboardMenuItem key="default-dashboard-menu-item" />
      {settingsResources.length > 0 && (
        <Accordion expanded={menuState.settings} onChange={(event, expanded) => handlerChange(expanded, "settings")} style={{ height: "fit-content", transition: "none", border: "none", borderColor: "transparent", boxShadow: "none", margin: 0, overflow: "hidden" }}>
          <AccordionSummary expandIcon={open ? <ExpandMoreIcon /> : null} sx={{ margin: 0, "&:hover": { backgroundColor: (theme) => theme.palette.action.hover } }}>
            <Grid
              container
              alignItems="center"
              spacing={1}
              sx={{
                marginTop: 0,
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Grid item style={{ margin: 0, padding: 0, paddingLeft: "0.5rem", alignItems: "center", justifyContent: "center", display: "flex" }} color="primary.menuIcon">
                <SettingsIcon />
              </Grid>
              {open && (
                <Grid item style={{ margin: 0, padding: 0 }}>
                  <Typography>Configuraciones</Typography>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0px 16px" }} sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
            <>
              {settingsResources.map((resource) => (
                <ResourceMenuItem key={resource.name} name={resource.name} label={resource.label} />
              ))}
              { permissions === "admin-sucursal" && <Menu.Item to={`/branch/${branchId}`} primaryText="Sucursal" leftIcon={<BusinessIcon />} />}
              <Menu.Item to="/tutorial" primaryText="Tutorial" leftIcon={<OndemandVideoIcon />} />
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {toolsResources.length > 0 && (
        <Accordion expanded={menuState.tools} onChange={(event, expanded) => handlerChange(expanded, "tools")} style={{ height: "fit-content", transition: "none", border: "none", borderColor: "transparent", boxShadow: "none", margin: 0, overflow: "hidden" }}>
          <AccordionSummary expandIcon={open ? <ExpandMoreIcon /> : null} sx={{ margin: 0, "&:hover": { backgroundColor: (theme) => theme.palette.action.hover } }}>
            <Grid container alignItems="center" spacing={1} sx={{ marginTop: 0, display: "flex", alignItems: "center", gap: "1rem" }}>
              <Grid item style={{ margin: 0, padding: 0, paddingLeft: "0.5rem", alignItems: "center", justifyContent: "center", display: "flex" }} color="primary.menuIcon">
                <BuildIcon />
              </Grid>
              {open && (
                <Grid item style={{ margin: 0, padding: 0 }}>
                  <Typography>Herramientas</Typography>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0px 16px" }} sx={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", position: "relative", flexShrink: 0 }}>
            <>
              {toolsResources.map((resource) => (
                <ResourceMenuItem key={resource.name} name={resource.name} label={resource.label} />
              ))}
              <Menu.Item to="/airtable" primaryText="Beneficios" leftIcon={<RedeemIcon/>}/>
            </>
          </AccordionDetails>
        </Accordion>
      )}

      {otherResources.length > 0 && (
        <Accordion expanded={menuState.other} onChange={(event, expanded) => handlerChange(expanded, "other")} style={{ height: "fit-content", transition: "none", border: "none", borderColor: "transparent", boxShadow: "none", margin: 0, overflow: "hidden" }}>
          <AccordionSummary expandIcon={open ? <ExpandMoreIcon /> : null} sx={{ margin: 0, "&:hover": { backgroundColor: (theme) => theme.palette.action.hover } }}>
            <Grid container alignItems="center" spacing={1} sx={{ marginTop: 0, display: "flex", alignItems: "center", gap: "1rem" }}>
              <Grid item style={{ margin: 0, padding: 0, paddingLeft: "0.5rem", alignItems: "center", justifyContent: "center", display: "flex" }} color="primary.menuIcon">
                <ShieldIcon />
              </Grid>
              <Grid item style={{ margin: 0, padding: 0 }}>
                {open && <Typography>Más opciones</Typography>}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ padding: "0px 16px" }}>
            <>
              {otherResources.map((resource) => (
                <ResourceMenuItem key={resource.name} name={resource.name} label={resource.label} />
              ))}
            </>
          </AccordionDetails>
        </Accordion>
      )}
    </Menu>
  );
};
