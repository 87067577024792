import React from "react";
import { useParams } from "react-router-dom";
import { useGetOne, Loading, Error } from "react-admin";
import { Box, Typography, Chip } from "@mui/material";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";

export const Show = () => {
  const params = useParams();
  const { data, isLoading, error } = useGetOne("enterprise/branches", params);

  if (isLoading) return <Loading />;
  if (error) return <Error message="Error al cargar los datos." />;

  const { addressInfo } = data;
  const { latitude, longitude } = addressInfo.coordinates || {};

  if (!latitude || !longitude) {
    return <Typography>No se puede mostrar el mapa. Coordenadas no disponibles.</Typography>;
  }

  const defaultProps = {
    center: {
      lat: latitude,
      lng: longitude,
    },
    zoom: 13,
  };

  return (
    <Box sx={{ margin: 8 }}>
      <Typography variant="h4">{data.name}</Typography>

      <Box mt={2} style={{ height: "400px", width: "100%" }}>
        <GoogleMapReact bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }} center={defaultProps.center} defaultZoom={defaultProps.zoom}>
          <RoomIcon
            lat={latitude}
            lng={longitude}
            style={{
              fontSize: 40,
              position: "relative",
              top: -40,
              left: -20,
            }}
          />
        </GoogleMapReact>
      </Box>

      <Box mt={2}>
        <Typography variant="h6">Dirección:</Typography>
        <Typography>{addressInfo.street}</Typography>
      </Box>

      <Box mt={2}>
        <Typography variant="h6">Teléfono/Celular:</Typography>
        <Typography>{addressInfo.phone}</Typography>
      </Box>
      {/* <Box mt={2}>
        <Typography variant="h6">Servicios:</Typography>
        {data.services && data.services.length > 0 ? (
          <Box>
            {data.services.map((serviceId, index) => (
              <Chip key={index} label={serviceId} sx={{ margin: "0.5rem" }} />
            ))}
          </Box>
        ) : (
          <Typography>No se han asignado servicios.</Typography>
        )}
      </Box> */}

      <Box mt={2}>
        <Typography variant="h6">Estado:</Typography>
        <Typography>{data.status ? "Activo" : "Inactivo"}</Typography>
      </Box>
    </Box>
  );
};
