import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import * as XLSX from "xlsx";
import PropTypes from "prop-types";
import {useExportBalance} from "./useExportBalance";

const ExportButton = ({ filename = "balance.xlsx" }) => {
  const { getExportData, data, loading } = useExportBalance();

  const handleExport = () => {
    if (!data ) return;
    
    const ingresosTotal = data.ingresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);
    const otrosIngresosTotal = data.otrosIngresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);
    const egresosTotal = data.egresosData.reduce((total, record) => total + parseFloat(record.Comisiones || 0), 0);
    const otrosEgresosTotal = data.otrosEgresosData.reduce((total, record) => total + parseFloat(record.Monto || 0), 0);    

    const totalIngresos = ingresosTotal + otrosIngresosTotal;
    const totalEgresos = egresosTotal + otrosEgresosTotal;
    const balanceFinal = totalIngresos - totalEgresos;
    
    const workbook = XLSX.utils.book_new();
    
    const ingresosSheet = XLSX.utils.json_to_sheet(data.ingresosData);
    const otrosIngresosSheet = XLSX.utils.json_to_sheet(data.otrosIngresosData);
    const egresosSheet = XLSX.utils.json_to_sheet(data.egresosData);
    const otrosEgresosSheet = XLSX.utils.json_to_sheet(data.otrosEgresosData);
    
    const summaryData = [
      { Tipo: "Total Ingresos", Monto: totalIngresos.toFixed(2) },
      { Tipo: "Total Egresos", Monto: totalEgresos.toFixed(2) },
      { Tipo: "Balance Final", Monto: balanceFinal.toFixed(2) },
    ];
    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    
    XLSX.utils.book_append_sheet(workbook, ingresosSheet, "Ingresos por Servicios");
    XLSX.utils.book_append_sheet(workbook, otrosIngresosSheet, "Otros Ingresos");
    XLSX.utils.book_append_sheet(workbook, egresosSheet, "Egresos por Comisiones");
    XLSX.utils.book_append_sheet(workbook, otrosEgresosSheet, "Otros Egresos");
    XLSX.utils.book_append_sheet(workbook, summarySheet, "Resumen");
    
    [ingresosSheet, otrosIngresosSheet, egresosSheet, otrosEgresosSheet, summarySheet].forEach((sheet) => {
      sheet["!cols"] = [{ wch: 15 }, { wch: 20 }, { wch: 30 }, { wch: 25 }, { wch: 15 }];
    });
    
    XLSX.writeFile(workbook, filename);
  };

  const handleClick = async () => {
    if (data && Object.keys(data).length > 0) {
      handleExport();
    } else {
      await getExportData();
    }
  };

  return (
    <Button onClick={handleClick} startIcon={<DownloadIcon />} disabled={loading}>
      {loading ? <CircularProgress size={24} /> : "Exportar"}
    </Button>
  );
};

ExportButton.propTypes = {
  filename: PropTypes.string,
};

export default ExportButton;
