import React from "react";
import { UserMenu, MenuItemLink, Logout } from "react-admin";
import SettingsIcon from "@mui/icons-material/Settings";

const MyUserMenuView = (props) => {

  const profile = JSON.parse(localStorage.getItem("profile"));

  // JSON.parse(localStorage.getItem("profile"))?.role != "admin";

  return (
    <UserMenu {...props}>
      {(profile?.role !== "admin" && profile?.role !== "admin-sucursal") && (
        <MenuItemLink
          to="/my-profile"
          primaryText="Mi Perfil"
          leftIcon={<SettingsIcon />}
        />
      )}
      {(profile?.role !== "admin" && profile?.role !== "admin-sucursal") && (
        <MenuItemLink
          to="/update-password"
          primaryText="Cambiar Contraseña"
          leftIcon={<SettingsIcon />}
        />
      )}
      <Logout />
    </UserMenu>
  );
};

export default MyUserMenuView;
