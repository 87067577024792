import React, { useState } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Geocode from "react-geocode";
import { useFormContext } from "react-hook-form";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);
Geocode.setLanguage("es");

export default function Map({
  initialLongitude = -63.18117,
  initialLatitude = -17.78629,
  source,
  disabled = false,
  ...props
}) {
  console.log("source", source);
  const { getValues, setValue } = useFormContext();
  const [coordInput, setCoordInput] = useState(
    getValues(source + ".coordinates")
  );
  const defaultProps = {
    center: {
      lat: coordInput?.latitude || initialLatitude,
      lng: coordInput?.longitude || initialLongitude,
    },
    zoom: 13,
  };

  const handleMapClick = ({ lat, lng }) => {
    if (disabled) return null;
    setValue(source + ".coordinates", { latitude: lat, longitude: lng });
    setCoordInput({ latitude: lat, longitude: lng });
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setValue(source + ".street", address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <div style={{ height: "500px", width: "100vh", marginBottom: 20 }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_KEY }}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => {
          map.addListener("click", (e) => {
            const lat = e.latLng.lat();
            const lng = e.latLng.lng();

            handleMapClick({ lat, lng });
          });
        }}
      >
        <RoomIcon
          lat={coordInput?.latitude || initialLatitude}
          lng={coordInput?.longitude || initialLongitude}
          style={{ fontSize: 40, position: "relative", top: -40, left: -20 }}
        />
      </GoogleMapReact>
    </div>
  );
}
